<template>
  <nav class="sidebar px-10">
    <div class="content">
      <div class="flex items-center justify-start gap-10 flex-1">
        <div class="logo">
          <img src="../assets/logo.svg" />
        </div>



        <div class="menu">
          <template v-if="isSettings">
            <div class="menu-item back" @click="isSettings = false">
              <div class="icon flip">
                <img src="../assets/icons/Arrow-right-alt.svg" />
              </div>
              <div class="name">
                Main Menu
              </div>
            </div>
            <div class="section-title">Artist</div>
            <router-link :to="{ name: 'ArtistProfile' }" v-slot="{ href, navigate, isActive }">
              <div @click="navigate" :class="[isActive && 'active']" class="menu-item">
                <div class="icon">
                  <img src="../assets/icons/Dashboard.svg" />
                </div>
                <div class="name">
                  <a :href="href">
                    Settings
                  </a>
                </div>
              </div>
            </router-link>
            <router-link :to="{ name: 'TeamManagement' }" v-slot="{ href, navigate, isExactActive }">
              <div @click="navigate" :class="[isExactActive && 'active']" class="menu-item">
                <div class="icon">
                  <img src="../assets/icons/Feedback.svg" />
                </div>
                <div class="name">
                  <a :href="href">
                    My Team
                  </a>
                </div>
              </div>
            </router-link>
            <div class="section-title">User</div>
            <router-link :to="{ name: 'UserProfile' }" v-slot="{ href, navigate, isActive }">
              <div @click="navigate" :class="[isActive && 'active']" class="menu-item">
                <div class="icon">
                  <img src="../assets/icons/Dashboard.svg" />
                </div>
                <div class="name">
                  <a :href="href">
                    Settings
                  </a>
                </div>
              </div>
            </router-link>
            <router-link :to="{ name: 'Subscription' }" v-slot="{ href, navigate, isExactActive }">
              <div @click="navigate" :class="[isExactActive && 'active']" class="menu-item">
                <div class="icon">
                  <img src="../assets/icons/Feedback.svg" />
                </div>
                <div class="name">
                  <a :href="href">
                    Subscription
                  </a>
                </div>
              </div>
            </router-link>
            <router-link :to="{ name: 'Payouts' }" v-slot="{ href, navigate, isExactActive }">
              <div @click="navigate" :class="[isExactActive && 'active']" class="menu-item">
                <div class="icon">
                  <img src="../assets/icons/Feedback.svg" />
                </div>
                <div class="name">
                  <a :href="href">
                    Payouts
                  </a>
                </div>
              </div>
            </router-link>
          </template>
          <template v-else>
            <!-- <router-link
            :to="{ name: 'Deals' }"
            v-slot="{ href, navigate, isActive }"
          >
            <div
              @click="navigate"
              :class="[isActive && 'active']"
              class="menu-item"
            >
              <div class="icon">
                <img src="../assets/icons/Deals.svg" />
              </div>
              <div class="name">
                <a :href="href">
                  Deals
                </a>
              </div>
              <div class="extra">
                <div class="notifications">
                  <img src="../assets/icons/Bell.svg" />
                  <span class="notifications-count">1</span>
                </div>
              </div>
            </div>
          </router-link> -->
            <!-- <div class="menu-item create-deal" @click="showModal = 'add-song'">
              <div class="icon">
                <img src="../assets/icons/CreateDeal.svg" />
              </div>
              <div class="name">
                Create Deal
              </div>
            </div> -->
            <!-- <router-link :to="{ name: 'Songs' }" v-slot="{ href, navigate, isActive }">
              <div @click="navigate" :class="[isActive && 'active']" class="menu-item">
                <div class="icon">
                  <img src="../assets/icons/Catalog.svg" />
                </div>
                <div class="name">
                  <a :href="href">
                    Songs
                  </a>
                </div>
                
              </div>
            </router-link> -->
            <router-link :to="{ name: 'Catalog' }" v-slot="{ href, navigate, isActive }">
              <div @click="navigate" :class="[isActive && 'active']" class="menu-item">
                <div class="icon">
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 18V5L21 3V16" :stroke="isActive ? '#FF035A' : 'white'" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round" />
                    <path
                      d="M6 21C7.65685 21 9 19.6569 9 18C9 16.3431 7.65685 15 6 15C4.34315 15 3 16.3431 3 18C3 19.6569 4.34315 21 6 21Z"
                      :stroke="isActive ? '#FF035A' : 'white'" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path
                      d="M18 19C19.6569 19 21 17.6569 21 16C21 14.3431 19.6569 13 18 13C16.3431 13 15 14.3431 15 16C15 17.6569 16.3431 19 18 19Z"
                      :stroke="isActive ? '#FF035A' : 'white'" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>


                </div>
                <div class="name">
                  <a :href="href">
                    Catalog
                  </a>
                </div>
                <!--
                <div class="extra">
                <div class="notifications">
                  <img src="../assets/icons/Bell.svg" />
                  <span class="notifications-count">1</span>
                </div>
              </div>
              -->
              </div>
            </router-link>
            <!-- <router-link
            :to="{ name: 'Feedback' }"
            v-slot="{ href, navigate, isExactActive }"
          >
            <div
              @click="navigate"
              :class="[isExactActive && 'active']"
              class="menu-item"
            >
              <div class="icon">
                <img src="../assets/icons/Feedback.svg" />
              </div>
              <div class="name">
                <a :href="href">
                  Feedback
                </a>
              </div>
            </div>
          </router-link> -->
            <router-link v-if="false" :to="{ name: 'Wallet' }" v-slot="{ href, navigate, isExactActive }">
              <div @click="navigate" :class="[isExactActive && 'active']" class="menu-item">
                <div class="icon">
                  <img src="../assets/icons/Wallet.svg" />
                </div>
                <div class="name">
                  <a :href="href">
                    Wallet
                  </a>
                </div>
                <!--
              <div class="extra">
                <p class="wallet-balance">$450</p>
              </div>
              -->
              </div>
            </router-link>
            <router-link :to="{ name: 'Contacts' }" v-slot="{ href, navigate, isActive }">
              <div @click="navigate" :class="[isActive && 'active']" class="menu-item">
                <div class="icon">
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21"
                      :stroke="isActive ? '#FF035A' : 'white'" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path
                      d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z"
                      :stroke="isActive ? '#FF035A' : 'white'" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path
                      d="M23 21.0028V19.0028C22.9993 18.1165 22.7044 17.2556 22.1614 16.5551C21.6184 15.8547 20.8581 15.3544 20 15.1328"
                      :stroke="isActive ? '#FF035A' : 'white'" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path
                      d="M16 3.13281C16.8604 3.35311 17.623 3.85351 18.1676 4.55512C18.7122 5.25673 19.0078 6.11964 19.0078 7.00781C19.0078 7.89598 18.7122 8.75889 18.1676 9.4605C17.623 10.1621 16.8604 10.6625 16 10.8828"
                      :stroke="isActive ? '#FF035A' : 'white'" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>

                </div>
                <div class="name">
                  <a :href="href">
                    Collaborators
                  </a>
                </div>
              </div>
            </router-link>

            <!--
          <div class="menu-item">
            <div class="icon">
              <img src="../assets/icons/Support.svg" />
            </div>
            <div class="name">
              Help Portal
            </div>
          </div>
          -->
          </template>
        </div>
      </div>

      <Dropdown class="">
        <template v-slot:button="{ toggleDropdown, isDropdownOpen }">
          <button @click="toggleDropdown" class="flex justify-between artist" style="width: 280px;">
            <div class="flex items-center justify-start flex-1">
              <img class="w-12 h-12 rounded-full object-cover" v-if="artist.avatar_url" :src="artist.avatar_url"
                alt="" />
              <div v-else
                class="w-14 h-14 rounded-full flex items-center justify-center text-xl font-sans bg-black border border-[#FF035A] text-white">
                {{ artist.name | initials }}</div>
              <p class="text-white pl-2 font-medium text-left text-lg max-w-[100px] truncate w-full">{{ artist.name }}
              </p>
              <!-- <span class="pro" v-if="isPro">PRO</span> -->
            </div>
            <span class="ml-auto">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down" width="24"
                height="24" viewBox="0 0 24 24" :style="isDropdownOpen ? 'transform: rotate(180deg)' : ''"
                stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M6 9l6 6l6 -6" />
              </svg>
            </span>
          </button>
        </template>

        <template v-slot:items="{ toggleDropdown }">
          <p class="px-2 mb-2 text-gray-400">User</p>
          <router-link style="margin-bottom: 3px;" :to="{ name: 'Settings', query: { type: 'user' } }"
            v-slot="{ href, navigate }">
            <div @click="() => {
            navigate();
            toggleDropdown();
          }
            " class="menu-item">
              <div class="icon">
                <img src="../assets/icons/Dashboard.svg" />
              </div>
              <div class="name">
                <a :href="href">
                  User Settings
                </a>
              </div>
            </div>
          </router-link>
          <hr class="my-2 border-gray-700" />

          <p class="px-2 mb-2 text-gray-400">Artist</p>
          <router-link style="margin-bottom: 3px;" :to="{ name: 'Settings', query: { type: 'artist' } }"
            v-slot="{ href, navigate }">
            <div @click="() => {
            navigate();
            toggleDropdown();
          }
            " class="menu-item">
              <div class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-microphone-2" width="24"
                  height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff90" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M15 12.9a5 5 0 1 0 -3.902 -3.9" />
                  <path d="M15 12.9l-3.902 -3.899l-7.513 8.584a2 2 0 1 0 2.827 2.83l8.588 -7.515z" />
                </svg>
              </div>
              <div class="name">
                <a :href="href">
                  Artist Settings
                </a>
              </div>
            </div>
          </router-link>
          <div class="menu-item" style="margin-bottom: 3px;" @click="() => {
            showModal = 'switch-artist';
            toggleDropdown();
          }
            ">
            <div class="icon">
              <img src="../assets/icons/Switch.svg" />
            </div>
            <div class="name">
              Switch Artist
            </div>
          </div>

          <hr class="my-2 border-gray-700" />
          <div style="margin-bottom: 0px;" class="menu-item" @click="() => {
            logout();
            toggleDropdown();
          }
            ">
            <div class="icon">
              <img src="../assets/icons/Logout.svg" />
            </div>
            <div class="name">
              Log out
            </div>
          </div>
        </template>
      </Dropdown>


      <!-- <div class="flex-wrap w-full bottom">
        <img class="n-logo" src="../assets/logo.png" /> &copy; 2024.
        <a href="/terms-of-use" class="link">Terms.</a>
        <a href="https://app.termly.io/document/privacy-policy/edbf75fb-d7c1-4c85-81b7-8f9a585e29d5"
          class="link">Privacy.</a>
      </div> -->
    </div>

    <SwitchArtist v-if="showModal === 'switch-artist'" @close="showModal = ''" />
    <AddSong :my-artist="artist" add-new-required v-if="showModal === 'add-song'" @close="showModal = ''" />
  </nav>
</template>

<script>
import SwitchArtist from "@/components/modals/SwitchArtist";
import AddSong from "@/components/modals/AddSong";
import Dropdown from "./Dropdown.vue";

export default {
  name: "Sidebar",
  components: { SwitchArtist, AddSong, Dropdown },
  data() {
    return {
      isSettings: false,
      showModal: ""
    };
  },
  mounted() {
    // this.isSettings = this.$route.path.includes("/settings");
  },
  watch: {
    // "$route.path"(val) {
    //   this.isSettings = val.includes("/settings");
    // }
  },
  filters: {
    initials(name) {
      return name
        ?.split(" ")
        .slice(0, 2)
        .map(el => el.charAt(0).toUpperCase())
        .join("");
    }
  },
  computed: {
    artist() {
      return this.$store.getters["account/getArtist"];
    },
    user() {
      return this.$store.getters["account/getUser"];
    },
    company() {
      return this.$store.getters["account/getCompany"];
    },
    isPro() {
      return !!this.user?.subscription?.artist_subscriptions.find(
        ({ artist_id }) => artist_id === this.artist?.id
      );
    }
  },
  methods: {
    logout() {
      this.$store.commit("account/logout");
      window.location.href = "/";
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.sidebar {
  // height: calc(100vh - 54px);
  width: 100%;
  background-color: #181818;
  border-radius: 20px 15px 15px 20px;
  position: relative;
  z-index: 100;
  display: flex;

  .menu-item {
    height: 48px;
    display: flex;
    color: $white;
    padding: 15px 24px;
    cursor: pointer;

    .icon {
      display: flex;
      align-items: center;

      img {
        height: 18px;
        width: 18px;
      }
    }

    .flip {
      transform: rotate(180deg);
    }

    .name {
      flex: 1;
      margin-left: 24px;
      display: flex;
      align-items: center;
      font-weight: 500;

      a {
        color: $white;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.2475px;
        text-decoration: none;
      }
    }

    .extra {
      display: flex;
      align-items: center;

      .notifications {
        display: flex;

        img {
          width: 16px;
          height: 16px;
        }

        .notifications-count {
          font-size: 10px;
          line-height: 12px;
          margin-left: -3px;
          margin-top: -5px;
        }
      }
    }

    &:hover {
      background-color: $text-additional;
      border-radius: 4px;
    }

    &.active {
      background-color: $white;
      border-radius: 4px;

      .icon {
        img {
          filter: brightness(0.5) sepia(1) saturate(10000%) hue-rotate(335deg);
        }
      }

      .name {
        color: $accent;

        a {
          color: $accent;
        }
      }
    }
  }

  .content {
    margin: 15px 0;
    // height: calc(100vh - 84px);
    width: 100%;
    font-weight: 300;
    display: flex;
    justify-content: space-between;
    // flex-direction: column;
    scrollbar-width: thin;
    scrollbar-color: $accent rgba(#cdcccc, 0.3);

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $accent;
      border-radius: 5px;
    }

    .logo {

      display: flex;
      justify-content: center;

      img {
        height: 20px;
      }
    }

    .avatar-url {
      height: 60px;
      width: 60px;
      border-radius: 50%;
      border: 1px $accent solid;
    }

    .initials {
      background-color: $black;
      border: 1px solid $accent;
      color: #fff;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      font-size: 26px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: sans-serif;
    }

    .artist {
      display: flex;
      align-items: center;
      padding: 0 27px;

      img {
        width: 52px;
        height: 52px;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        filter: drop-shadow(0px 4px 5px rgba(255, 255, 255, 0.25));
        border-radius: 25px;
      }

      span {
        margin-left: 16px;
        margin-right: 5px;
        color: $white;
        font-size: 18px;
        line-height: 21px;
        font-weight: 500;
        text-align: center;
        letter-spacing: -0.2475px;
      }

      .name {
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }

      .pro {
        background-color: $accent;
        line-height: 12px;
        font-size: 12px;
        padding: 1px 2px 2px;
        border-radius: 3px;
        margin-top: 6px;
        margin-left: auto;
      }
    }

    .menu {
      padding: 0 20px;
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 10px;

      .divider {
        height: 1px;
        width: 90%;
        margin: 1rem 5%;
        background-color: white;
      }

      .create-deal {
        border: 1px solid $white;
        border-radius: 4px;
      }

      .create-deal:hover {
        background: $accent !important;
      }

      .menu-item {
        height: 48px;
        display: flex;
        color: $white;
        padding: 15px 24px;
        cursor: pointer;

        .icon {
          display: flex;
          align-items: center;

          img {
            height: 18px;
            width: 18px;
          }
        }

        .flip {
          transform: rotate(180deg);
        }

        .name {
          flex: 1;
          margin-left: 24px;
          display: flex;
          align-items: center;
          font-weight: 500;

          a {
            color: $white;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: -0.2475px;
            text-decoration: none;
          }
        }

        .extra {
          display: flex;
          align-items: center;

          .notifications {
            display: flex;

            img {
              width: 16px;
              height: 16px;
            }

            .notifications-count {
              font-size: 10px;
              line-height: 12px;
              margin-left: -3px;
              margin-top: -5px;
            }
          }
        }

        &:hover {
          background-color: $text-additional;
          border-radius: 4px;
        }

        &.active {
          background-color: $white;
          border-radius: 4px;

          .icon {
            img {
              filter: brightness(0.5) sepia(1) saturate(10000%) hue-rotate(335deg);
            }
          }

          .name {
            color: $accent;

            a {
              color: $accent;
            }
          }
        }
      }

      .section-title {
        height: 48px;
        display: flex;
        color: $white;
        padding: 5px 24px 13px;
        font-weight: 500;
        font-size: 1.1rem;
      }

      .back {
        border: 1px #616161 solid;
        border-radius: 4px;
      }
    }

    .bottom {
      display: flex;
      padding: 1rem 1.5rem;
      align-items: center;
      // margin-top: auto;
      color: #fff;

      .n-logo {
        height: 24px;
        margin-right: 10px;
        display: inline-block;
      }

      a {
        margin: 2px;
        color: #eee;
        text-decoration: none;
      }

      a:hover {
        text-decoration: underline;
      }
    }

    .user {
      display: flex;
      padding: 1rem 1.5rem;
      align-items: center;
      margin-top: auto;

      .avatar-url {
        height: 45px;
        width: 45px;
      }

      .initials {
        width: 45px;
        height: 45px;
        font-size: 20px;
      }

      .details {
        margin-left: 14px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        span,
        b {
          color: $white;
          line-height: 21px;
          text-align: center;
          letter-spacing: -0.2475px;
        }

        b {
          font-size: 18px;
        }
      }
    }
  }
}
</style>
