import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/main.scss";

import VueApollo from "vue-apollo";

import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { WebSocketLink } from "apollo-link-ws";

import * as ScrollMagic from "scrollmagic";
import { TweenMax, TimelineMax } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import { createHttpLink } from "apollo-link-http";
import { ApolloLink } from "apollo-boost";
import { onError } from "apollo-link-error";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import VPopover from "vue-js-popover";
import trackContractHistory from "@/mixins/trackHistory";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
Vue.use(VueToast);
Vue.use(VPopover, { tooltip: true, pointer: true });

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

Vue.use(VueApollo);

Vue.mixin(trackContractHistory);

Vue.config.productionTip = false;

let token = store.getters["account/getApolloToken"];

Object.defineProperty(Vue.prototype, "$token", {
  get() {
    return token;
  },
  set(value) {
    token = value;
  },
});

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_HTTPS_API,
});

let lastError = null;
let errorCount = 0;
let totalRecentErrorCount = 0;
let lastErrorAt = null;

// eslint-disable-next-line no-unused-vars
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const now = new Date();
  if (lastErrorAt && now - lastErrorAt <= 4000) {
    totalRecentErrorCount++;
  } else totalRecentErrorCount = 0;
  lastErrorAt = now;
  console.log({
    operationName: operation.operationName,
    variables: JSON.stringify(operation.variables || {}),
    query: operation.query.loc?.source.body,
  });

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(`GraphQL error`, locations, path, message);
      console.error(`GraphQL error: ${message}`);
      if (lastError === message) {
        errorCount += 1;
      } else {
        lastError = message;
        errorCount = 1;
      }

      if (errorCount >= 3) {
        // localStorage.clear();
        // store.dispatch("resetState");

        errorCount = 0;
      }
    });
  }

  if (networkError) {
    console.error(`Network error: ${networkError.message}`);
    if (lastError === networkError?.extensions?.code) {
      errorCount += 1;
    } else {
      lastError = networkError?.extensions?.code || null;
      errorCount = 1;
    }

    if (errorCount >= 3) {
      // localStorage.clear();
      // store.dispatch("resetState");

      errorCount = 0;
    }
  }

  if (totalRecentErrorCount >= 5) {
    // localStorage.clear();
    // store.dispatch("resetState");

    location.reload();

    errorCount = 0;
    totalRecentErrorCount = 0;
  }
});

const getWsHeaders = () => {
  if (token && token.length && token !== null && token !== "null") {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  } else {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
};

const wsHeaders = getWsHeaders();

const wsLink = new WebSocketLink({
  uri: process.env.VUE_APP_WSS_API,
  options: {
    reconnect: true,
    connectionParams: () => ({
      ...wsHeaders,
    }),
  },
});

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  if (token && token.length && token !== null && token !== "null") {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    };
  } else {
    return {
      headers: {
        ...headers,
      },
    };
  }
});

// HTTP connection to the local API
const localHttpLink = createHttpLink({
  uri: process.env.VUE_APP_GRAPHQL_HTTP,
});

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: true,
  }),
});

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new CaptureConsoleIntegration({
      levels: ["error"],
    }),
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "api.nvoko.com", /^\//],
    }),
    new Sentry.Replay({
      networkDetailAllowUrls: ["properties://browser/clickID"],
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 1.0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  async beforeSend(event) {
    if (event.exception) {
      event.extra = {
        ...event.extra,
        state: store.state,
      };
    }
    return event;
  },
});

export const localClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, localHttpLink]),
  cache: new InMemoryCache({
    addTypename: true,
  }),
});

const apolloProvider = new VueApollo({
  defaultClient: client,
  clients: {
    localClient,
  },
});

Vue.mixin({
  methods: {
    resetSocketConnection: () => {
      wsLink.subscriptionClient.close();
    },
  },
});

new Vue({
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
  mounted() {},
}).$mount("#app");
